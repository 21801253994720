import React, { useState, useEffect } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import {navigate} from "gatsby";
import {Button} from "@blueprintjs/core";
import {Trans} from "react-i18next";

// This poster image is used for videos while loading
import biuwerPosterImgPath from '../../../static/img/biuwer-easy-to-use.png';

// Rest of media paths (icons, images and videos)
import easyToUseVideoPath from '../../../static/video/biuwer-ease-of-use-demo-video.mp4';
import easyToUseIconPath from '../../../static/img/biuwer-easy-to-use-icon.png';
import dataUsedEveryoneImgPath from '../../../static/img/biuwer-unlimited-basic-users.png';
import dataUsedEveryoneIconPath from '../../../static/img/biuwer-data-used-by-everyone-icon.png';
import onePlatformVideoPath from '../../../static/video/biuwer-data-center-demo-video.mp4';
import onePlatformIconPath from '../../../static/img/biuwer-one-platform-many-use-cases-icon.png';
import saveTimeMoneyImgPath from '../../../static/img/biuwer-share-content-embedded-analytics.png';
import saveTimeMoneyIconPath from '../../../static/img/biuwer-save-time-and-money-icon.png';

const WhyBiuwerSection = (props) => {
    const { t, i18n } = props;

    const [selectedMedia, setSelectedMedia] = useState('easyToUse');
    const [selectedMediaContent, setSelectedMediaContent] = useState(easyToUseVideoPath);
    const [mediaType, setMediaType] = useState('video');
    const [hoveredBlock, setHoveredBlock] = useState(null);

    const styles = {
        section: {
            padding: '40px 10px'
        },
        media: {
            width: '96%',
            margin: '30px 0',
            boxShadow: 'rgb(0 0 0 / 5%) 0px 10px 30px, rgb(0 0 0 / 10%) 0px 20px 60px'
        },
        wrapper: {
            position: 'relative',
            top: 0,
            transition: 'top 0.5s ease-in-out'
        },
        activeWrapper: {
            borderRadius: 5,
            backgroundColor: 'white',
            transition: 'box-shadow 0.5s ease-in-out'
        },
        hoveredWrapper: {
            '&:hover': {
                boxShadow: 'rgb(0 0 0 / 5%) 0px 5px 15px, rgb(0 0 0 / 10%) 0px 10px 30px',
                top: -4,
                cursor: 'pointer'
            }
        },
        blockHeader: {
            display: 'flex',
            alignItems: 'center'
        }
    };

    const getMediaContent = () => {
        switch (selectedMedia) {
            case 'easyToUse':
                setSelectedMediaContent(easyToUseVideoPath);
                setMediaType('video');
                break;
            case 'dataUsedEveryone':
                setSelectedMediaContent(dataUsedEveryoneImgPath);
                setMediaType('image');
                break;
            case 'onePlatform':
                setSelectedMediaContent(onePlatformVideoPath);
                setMediaType('video');
                break;
            case 'saveTimeMoney':
                setSelectedMediaContent(saveTimeMoneyImgPath);
                setMediaType('image');
                break;
            default:
                setSelectedMediaContent(easyToUseVideoPath);
                setMediaType('video');
        }
    };

    // Get the image content on image change
    useEffect(() => {
        getMediaContent();
    }, [selectedMedia]); //eslint-disable-line

    return (
        <div style={{ backgroundColor: '#F9FBFD' }}>
            <div className="container" style={styles.section}>
                <div className="row mb30">
                    <div className="col-md-6 col-lg-6">
                        <h2>{t('home:whyBiuwerSection.title')}</h2>
                        <p>{t('home:whyBiuwerSection.description')}</p>
                        <div className="text-center">
                        {mediaType === 'image' ? (
                            <img
                                src={selectedMediaContent}
                                alt={t('home:whyBiuwerSection.title')}
                                style={styles.media}
                            />
                        ) : (
                            <video loop muted autoPlay
                                   poster={biuwerPosterImgPath}
                                   style={styles.media}
                            >
                                <source src={selectedMediaContent} type="video/mp4" />
                            </video>
                        )}
                        </div>
                        <div className="text-center mb30">
                            <Button
                                text={t("home:whyBiuwerSection.productDetails").toUpperCase()}
                                intent="primary"
                                style={{ boxShadow: 'none' }}
                                large={true}
                                onClick={() => i18n.language === 'en' ? navigate('/en/product') : navigate('/es/producto')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="row mb30">
                            <div className="col-sm-12">
                                <div
                                    className="p-4"
                                    css={
                                        selectedMedia === 'easyToUse' ?
                                        hoveredBlock === 'easyToUse' ?
                                            {...styles.wrapper, ...styles.activeWrapper, ...styles.hoveredWrapper } :
                                            {...styles.wrapper, ...styles.activeWrapper } :
                                            styles.wrapper
                                    }
                                    onMouseOver={() => { setSelectedMedia('easyToUse'); setHoveredBlock('easyToUse'); }}
                                    onFocus={() => void 0}
                                    onMouseOut={() => setHoveredBlock(null)}
                                    onBlur={() => void 0}
                                    role="link"
                                    tabIndex={0}
                                >
                                    <div className="mb-2" css={styles.blockHeader}>
                                        <img src={easyToUseIconPath} width={32} height={32} alt={t('home:whyBiuwerSection.easeOfUse')} />
                                        <h5 className="mb-0 ml10">{t('home:whyBiuwerSection.easeOfUse')}</h5>
                                    </div>
                                    <p className="mb-0">
                                        <Trans i18nKey="home:whyBiuwerSection.easeOfUseMsg">
                                            S0<strong>S1</strong>S2<strong>S3</strong>S4
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div
                                    className="p-4"
                                    css={
                                        selectedMedia === 'dataUsedEveryone' ?
                                            hoveredBlock === 'dataUsedEveryone' ?
                                                {...styles.wrapper, ...styles.activeWrapper, ...styles.hoveredWrapper } :
                                                {...styles.wrapper, ...styles.activeWrapper } :
                                            styles.wrapper
                                    }
                                    onMouseOver={() => { setSelectedMedia('dataUsedEveryone'); setHoveredBlock('dataUsedEveryone'); }}
                                    onFocus={() => void 0}
                                    onMouseOut={() => setHoveredBlock(null)}
                                    onBlur={() => void 0}
                                    role="link"
                                    tabIndex={0}
                                >
                                    <div className="mb-2" css={styles.blockHeader}>
                                        <img src={dataUsedEveryoneIconPath} width={32} height={32} alt={t('home:whyBiuwerSection.dataUsedEveryone')} />
                                        <h5 className="mb-0 ml10">{t('home:whyBiuwerSection.dataUsedEveryone')}</h5>
                                    </div>
                                    <p className="mb-0">
                                        <Trans i18nKey="home:whyBiuwerSection.dataUsedEveryoneMsg">
                                            S0<strong>S1</strong>S2
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div
                                    className="p-4"
                                    css={
                                        selectedMedia === 'onePlatform' ?
                                            hoveredBlock === 'onePlatform' ?
                                                {...styles.wrapper, ...styles.activeWrapper, ...styles.hoveredWrapper } :
                                                {...styles.wrapper, ...styles.activeWrapper } :
                                            styles.wrapper
                                    }
                                    onMouseOver={() => { setSelectedMedia('onePlatform'); setHoveredBlock('onePlatform'); }}
                                    onFocus={() => void 0}
                                    onMouseOut={() => setHoveredBlock(null)}
                                    onBlur={() => void 0}
                                    role="link"
                                    tabIndex={0}
                                >
                                    <div className="mb-2" css={styles.blockHeader}>
                                        <img src={onePlatformIconPath} width={32} height={32} alt={t('home:whyBiuwerSection.onePlatform')} />
                                        <h5 className="mb-0 ml10">{t('home:whyBiuwerSection.onePlatform')}</h5>
                                    </div>
                                    <p className="mb-0">
                                        <Trans i18nKey="home:whyBiuwerSection.onePlatformMsg">
                                            S0<strong>S1</strong>S2<strong>S3</strong>S4
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div
                                    className="p-4"
                                    css={
                                        selectedMedia === 'saveTimeMoney' ?
                                            hoveredBlock === 'saveTimeMoney' ?
                                                {...styles.wrapper, ...styles.activeWrapper, ...styles.hoveredWrapper } :
                                                {...styles.wrapper, ...styles.activeWrapper } :
                                            styles.wrapper
                                    }
                                    onMouseOver={() => { setSelectedMedia('saveTimeMoney'); setHoveredBlock('saveTimeMoney'); }}
                                    onFocus={() => void 0}
                                    onMouseOut={() => setHoveredBlock(null)}
                                    onBlur={() => void 0}
                                    role="link"
                                    tabIndex={0}
                                >
                                    <div className="mb-2" css={styles.blockHeader}>
                                        <img src={saveTimeMoneyIconPath} width={32} height={32} alt={t('home:whyBiuwerSection.saveTimeMoney')} />
                                        <h5 className="mb-0 ml10">{t('home:whyBiuwerSection.saveTimeMoney')}</h5>
                                    </div>
                                    <p className="mb-0">
                                        <Trans i18nKey="home:whyBiuwerSection.saveTimeMoneyMsg">
                                            S0<strong>S1</strong>S2<strong>S3</strong>
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ borderTop: '1px solid #CCC', paddingTop: 30 }}>
                    <div className="col-md-6 col-lg-3 mb40">
                        <h4 style={{ fontWeight: 'bold' }}>{t('home:whyBiuwerSection.cloudBased')}</h4>
                        <p>{t('home:whyBiuwerSection.cloudBasedMsg')}</p>
                    </div>
                    <div className="col-md-6 col-lg-3 mb40">
                        <h4 style={{ fontWeight: 'bold' }}>{t('home:whyBiuwerSection.noCodingRequired')}</h4>
                        <p>{t('home:whyBiuwerSection.noCodingRequiredMsg')}</p>
                    </div>
                    <div className="col-md-6 col-lg-3 mb40">
                        <h4 style={{ fontWeight: 'bold' }}>{t('home:whyBiuwerSection.customizable')}</h4>
                        <p>{t('home:whyBiuwerSection.customizableMsg')}</p>
                    </div>
                    <div className="col-md-6 col-lg-3 mb40">
                        <h4 style={{ fontWeight: 'bold' }}>{t('home:whyBiuwerSection.affordablePricing')}</h4>
                        <p>{t('home:whyBiuwerSection.affordablePricingMsg')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyBiuwerSection;