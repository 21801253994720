import React from 'react';

import clientLogoActua from '../../../static/img/logos/client-logo-actua.png';
import clientLogoMesaDelCastillo from '../../../static/img/logos/client-logo-mesa-del-castillo.png';
import clientLogoCosoltrans from '../../../static/img/logos/client-logo-cosoltrans.png';
import clientLogoOrthem from '../../../static/img/logos/client-logo-orthem.png';
import clientLogoAlsur from '../../../static/img/logos/client-logo-alsur.png';
import clientLogoNeuromobile from '../../../static/img/logos/client-logo-neuromobile.png';

const ClientsSection = () => {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imgElement: {
            margin: 10,
            width: 160,
            filter: 'grayscale(100%)',
            opacity: 0.6
        },
        helper: {
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'middle'
        }
    };

    return (
        <div className="container">
            <div className="row mv40">
                <div className="col-12" style={styles.container}>
                    <div>
                        <img src={clientLogoActua} style={styles.imgElement} alt="" />
                    </div>
                    <div>
                        <img src={clientLogoMesaDelCastillo} style={styles.imgElement} alt="" />
                    </div>
                    <div>
                        <img src={clientLogoCosoltrans} style={{...styles.imgElement }} alt="" />
                    </div>
                    <div>
                        <img src={clientLogoOrthem} style={styles.imgElement} alt="" />
                    </div>
                    <div>
                        <img src={clientLogoAlsur} style={{...styles.imgElement }} alt="" />
                    </div>
                    <div>
                        <img src={clientLogoNeuromobile} style={styles.imgElement} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientsSection;