import React from 'react';
import { useTranslation } from 'react-i18next';

// Content components
import MainLayout from '../../components/main-layout';
import HomeHeaderSection from './header-section';
import ClientsSection from './clients-section';
import MeetBiuwerSection from './meet-biuwer-section';
import WhyBiuwerSection from './why-biuwer-section';
// import KitDigitalSection from '../../components/kit-digital-section';
// import EcommerceSolutionSection from '../../components/ecommerce-solution-section';
import RequestDemoSection from '../../components/RequestDemoSection';

const Homepage = (props) => {
    const { t, i18n } = useTranslation(),
        { pageContext } = props;

    return (
        <MainLayout pageContext={pageContext}>
            <HomeHeaderSection key="home-header" {...props} t={t} i18n={i18n} />
            <ClientsSection key="home-clients" />
            <MeetBiuwerSection key="home-meet-biuwer" {...props} t={t} i18n={i18n} />
            <WhyBiuwerSection key="home-why-biuwer" {...props} t={t} i18n={i18n} />
            {/* <KitDigitalSection key="home-kit-digital" {...props} t={t} i18n={i18n} /> */}
            {/* <EcommerceSolutionSection key="home-ecommerce-solution" {...props} t={t} i18n={i18n} /> */}
            <RequestDemoSection key="home-4" {...props} t={t} i18n={i18n} />
        </MainLayout>
    );
}

export default Homepage;