import React from 'react';

import biuwerDataPlatformDiagramImgPathEn from '../../../static/img/biuwer-data-platform-diagram-en.png';
import biuwerDataPlatformDiagramImgPathEs from '../../../static/img/biuwer-data-platform-diagram-es.png';

const MeetBiuwerSection = (props) => {
    const { t, i18n } = props;

    const styles = {
        section: {
            padding: '40px 10px'
        }
    };

    return (
        <div className="container" style={styles.section}>
            <div className="row mb30">
                <div className="col-12" style={{ textAlign: 'center' }}>
                    <h2>{t('home:meetBiuwerSection.title')}</h2>
                    <h6>{t('home:meetBiuwerSection.description')}</h6>
                    <h6>{t('home:meetBiuwerSection.trustMsg')}</h6>
                </div>
            </div>
            <div className="row mb30">
                <div className="col-12" style={{ textAlign: 'center' }}>
                    <img
                        src={i18n.language === 'en' ? biuwerDataPlatformDiagramImgPathEn : biuwerDataPlatformDiagramImgPathEs}
                        alt={t('home:meetBiuwerSection.title')}
                        style={{ width: '100%' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default MeetBiuwerSection;