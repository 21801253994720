import React from 'react';
import { navigate } from 'gatsby';
import { Button } from '@blueprintjs/core';
/** @jsx jsx */
import { jsx } from '@emotion/react';

import mainImage from '../../../static/img/business-intelligence-dashboard-biuwer.png';

const HomeHeaderSection = (props) => {
    const { t, i18n } = props;

    const styles = {
        imgStyle: {
            maxWidth: '100%'
        },
        textStyle: {
            color: '#666'
        }
    };

    return (
        <div className="container pt40" style={{ marginTop: 72 }}>
            <div className="row mv40">
                <div className="col-sm-12 col-lg-5 mb20">
                    <h1 className="mb20">{t('home:headerSection.msg1')}</h1>
                    <h5 className="mb40" style={{ fontWeight: 400 }}>{t('home:headerSection.msg2')}</h5>
                    <div className="mb20">
                        <Button
                            text={t("mainMenuLinks.requestDemo").toUpperCase()}
                            intent="primary"
                            style={{ boxShadow: 'none', marginRight: 20 }}
                            large={true}
                            onClick={() => i18n.language === 'en' ? navigate('/en/book-demo') : navigate('/es/reserva-demo')}
                        />
                        <a
                            href={`https://app.biuwer.com/signup?language=${i18n.language || 'en'}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-success"
                            style={{ color: 'white', textDecoration: 'none', height: 40 }}
                        >
                            {t("mainMenuLinks.tryForFree").toUpperCase()}
                        </a>
                    </div>
                    <div className="mv10" style={styles.textStyle}>{t('home:headerSection.msg3')}</div>
                    <div className="mv10" style={styles.textStyle}>{t('home:headerSection.msg4')}</div>
                    <div className="mv10" style={styles.textStyle}>{t('home:headerSection.msg5')}</div>
                </div>
                <div className="col-sm-12 col-lg-7 mb20">
                    <img style={styles.imgStyle}
                        src={mainImage}
                        alt={t('siteTitle')}
                    />
                </div>
            </div>
        </div>
    );
}

export default HomeHeaderSection;